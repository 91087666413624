import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Box, Skeleton } from '@mui/material';

import addDays from '../../utils/date/addDays';
import addMinutes from '../../utils/date/addMinutes';
import convertToString from '../../utils/date/convertToString';
import getDatesBetween from '../../utils/date/getDatesBetween';
import isBefore from '../../utils/date/isBefore';
import isBetween from '../../utils/date/isBetween';
import isSameTime from '../../utils/date/isSameTime';
import isSameDayDate from '../../utils/date/isSameDayDate';
import isSameWeek from '../../utils/date/isSameWeek';
import { default as convertTimeToString } from '../../utils/time/convertToString';
import isWeekend from '../../utils/date/isWeekend';
import setTime from '../../utils/date/setTime';
import { formatTime } from '../../utils';

import Hoverbox from '../Hoverbox';

const HOURS = 24;
const WEEKDAYS = 7;
const MINUTES = 60;

function timeStringToHourDecimal(string) {
  const maybeFormattedTime = formatTime(string);

  if (maybeFormattedTime) {
    return maybeFormattedTime.hour + maybeFormattedTime.minutes / 60;
  }

  return 0;
}

function sortByStartDateAsc(elem1, elem2) {
  return new Date(elem1) - new Date(elem2);
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    overflow: 'auto'
  },
  units: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '0.5rem'
  },
  unit: {
    border: ({ props }) =>
      `1px solid ${
        props.customize?.style?.textColor ?? theme.color.common.grey.main
      }`,
    borderRadius: '50%',
    color: ({ props }) =>
      props.customize?.style?.textColor ?? theme.color.common.grey.main,
    cursor: 'help',
    flexShrink: 0,
    fontSize: '0.8rem',
    lineHeight: '1.2rem',
    textAlign: 'center',
    width: '1.2rem',
    '&:hover': {
      background: theme.color.primary.main,
      color: theme.color.primary.contrastText,
      borderColor: theme.color.primary.main
    }
  },
  labels: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    textAlign: 'center',
    gap: '.5rem'
  },
  domain: {
    fontWeight: 600,
    marginBottom: '.3rem'
  },
  group: {
    marginBottom: '.3rem'
  },
  days: {
    display: 'flex',
    fontSize: '1rem',
    marginLeft: ({ props, state }) =>
      state.config.offset + state.config.timesOffset
  },
  dayWrapper: {
    flexShrink: 0,
    width: ({ state, propsRef }) => {
      const u = sortUnits(propsRef?.current.group?.units);

      return state.config.slotWidth * u.length;
    }
  },
  day: {
    color: ({ props }) =>
      props.customize?.style?.textColor ?? theme.color.text.main,
    flexShrink: 0,
    fontWeight: 600,
    minHeight: '2rem',
    textAlign: 'center',
    width: '100%'
  },
  weekendDay: {
    color: theme.color.text.light
  },
  timetable: {
    opacity: ({ state }) => (state.loading ? 0.5 : 1),
    pointerEvents: ({ state }) => (state.loading ? 'none' : 'auto'),
    height: ({ state, props }) =>
      timeStringToHourDecimal(props.showTill) * state.config.slotHeight +
      state.config.offset -
      timeStringToHourDecimal(props.showFrom) * state.config.slotHeight +
      state.config.offset,
    width: ({ state, props, propsRef }) => {
      const u = sortUnits(propsRef?.current.group?.units);

      return (
        state.config.slotWidth * u.length * props.days.length +
        state.config.offset +
        state.config.timesOffset
      );
    },

    minWidth: '100%',
    overflow: 'hidden'
  },
  timeAndEvents: {
    position: 'relative',
    display: 'flex',
    flexShrink: 0,
    marginTop: ({ props, state }) =>
      -(timeStringToHourDecimal(props.showFrom) * state.config.slotHeight)
  },
  times: {
    flexShrink: 0,
    fontSize: '1rem',
    width: ({ state, props }) => state.config.timesOffset
  },
  time: {
    color: theme.color.text.main,
    height: ({ state }) => state.config.slotHeight
  },
  elements: {
    overflow: 'hidden',
    position: 'relative'
  },
  element: {
    alignItems: 'center',
    background: theme.color.primary.main,
    border: `.5px solid ${theme.color.background.default}`,
    borderRadius: '0.5rem',
    boxSizing: 'border-box',
    color: theme.color.text.main,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '0.75rem',
    lineHeight: 'normal',
    padding: '1rem .1rem',
    position: 'absolute',
    textAlign: 'center',
    userSelect: 'none'
  },
  hoverable: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  elementsAllDay: {
    width: '100%',
    // paddingRight: ({ state }) => state.config.slotPadding,
    boxSizing: 'border-box'
  },
  elementAllDay: {
    background: theme.color.primary.main,
    color: theme.color.text.main,
    marginTop: '0.15rem',
    marginBottom: '0.15rem',
    borderRadius: '0.25rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0 .25rem',
    width: '100%',
    boxSizing: 'border-box'
  },
  dateEndResize: {
    position: 'absolute',
    bottom: 0,
    boxSizing: 'border-box',
    padding: '.25rem',
    height: '1px',
    width: '100%',
    opacity: 0.2,
    '&:hover': {
      cursor: 'row-resize'
    }
  },
  unitsResize: {
    position: 'absolute',
    top: 0,
    right: 0,
    boxSizing: 'border-box',
    padding: '.25rem',
    height: '100%',
    width: '1px',
    opacity: 0.2,
    '&:hover': {
      cursor: 'col-resize'
    }
  },
  loadingGrid: {
    background: theme.color.border.main,
    height: '1px'
  }
}));

function sortUnits(units) {
  if (!units) return [];

  // Copy units to make sure we do not mutate redux array
  return [...units].sort((a, b) => a.order - b.order);
}

export default function TimeTableWeek(props) {
  const DEFAULT_CONFIG = {
    timesOffset: 48, // Fixed width of timeline. Should be moved to dynamic calculation.
    offset: props.customize?.style?.offset ?? 10, // The offset to make clear events hang over
    slotWidth: 32, // Width a single unit
    slotHeight: 64, // Height of 1 hour
    slotPadding: 8, // Space set free to enable click on unit with running event
    timeStep: 15, // Steps in timetable - 5mins
    lineWidth: 0.5, // Width of grid lines
    thinLineWidth: 0.1 // Width of thin grid lines
  };

  const propsRef = useRef(props);
  propsRef.current = props;
  const [config, setConfig] = useState({
    ...DEFAULT_CONFIG,
    units: sortUnits(props.group?.units),
    id: `timetable-${Math.floor(Math.random() * 100)}`
  });
  const configRef = useRef(config);
  configRef.current = config;

  const [loading, setLoading] = useState(props.loading);
  const [initialized, setInitialized] = useState(false);
  const [data, setData] = useState(props.data);

  const [clickDummy, setClickDummy] = useState();
  const theme = useTheme();
  const classes = useStyles({
    props,
    propsRef,
    state: { config, loading, initialized }
  });

  const canvasRef = useRef(null);
  const wrapperRef = useRef(null);
  const elementsRef = useRef();
  const clickDummyRef = useRef();
  let resizeTimeout = null;

  /*
  Make sure we update config
  */
  useEffect(() => {
    setConfig(c => {
      const updatedConfig = {
        ...c,
        offset: props.customize?.style?.offset ?? DEFAULT_CONFIG.offset,
        timesOffset:
          props.customize?.style?.timesOffset ?? DEFAULT_CONFIG.timesOffset,
        strokeStyle:
          props.customize?.style?.strokeStyle ?? DEFAULT_CONFIG.strokeStyle,
        lineWidth:
          props.customize?.style?.lineWidth ?? DEFAULT_CONFIG.lineWidth,
        thinLineWidth:
          props.customize?.style?.thinLineWidth ?? DEFAULT_CONFIG.thinLineWidth
      };
      updatedConfig.slotWidth = calculateSlotWidth(updatedConfig);
      return updatedConfig;
    });
  }, [props.customize?.style]);

  /*
  Make sure local events are always up to date
  */
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  /*
  Allow simple updating events on drag and drop/resize change
  */
  function updateData(element, entity) {
    const updatedData = { ...data };

    for (let i = 0; i < data[entity].length; i += 1) {
      if (data[entity][i].id === element.id) {
        updatedData[entity][i] = element;
      }
    }

    setData(updatedData);
  }

  /*
  Calculate slot width based on units and screen size
  */
  function calculateSlotWidth(c) {
    const u = sortUnits(propsRef?.current.group?.units);
    const slotWidth =
      wrapperRef?.current?.clientWidth <= 0 || u.length <= 0
        ? DEFAULT_CONFIG.slotWidth
        : (wrapperRef?.current?.clientWidth - c.timesOffset - c.offset) /
          (u.length * props.days.length);
    // Make sure slot size is big enough to display
    // unit labels
    return slotWidth < DEFAULT_CONFIG.slotWidth
      ? DEFAULT_CONFIG.slotWidth
      : slotWidth;
  }

  /*
  This effect runs on initial client side render and calculates
  the slot width based on user screen size. It recalculates
  the slot width on window resize.
  */
  useEffect(() => {
    if (props.group?.units?.length) {
      setTimeout(() => {
        setInitialized(true);
      }, 100);
    }

    setConfig({
      ...config,
      slotWidth: calculateSlotWidth(config),
      group: props.group,
      units: sortUnits(props.group?.units)
    });

    function handleResize() {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(async () => {
        setConfig({
          ...configRef.current,
          slotWidth: calculateSlotWidth(configRef.current)
        });
      }, 100);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  /*
  Make sure we recalculate on group change
  */
  useEffect(() => {
    if (
      props.group?.units?.length &&
      JSON.stringify(props.group) !== JSON.stringify(config.group)
    ) {
      setConfig({
        ...config,
        group: props.group,
        units: sortUnits(props.group?.units),
        slotWidth: calculateSlotWidth(config)
      });

      setTimeout(() => {
        setInitialized(true);
      }, 100);
    }
  }, [props.group]);

  /*
    The component config contains all necessary information
    for rendering. This effect paints a canvas on config change.
    The canvas acts as background for the timetable.
    */
  useEffect(() => {
    if (canvasRef?.current) {
      const canvas = canvasRef.current;
      canvas.id = config.id;
      canvas.width =
        config.slotWidth * config.units.length * props.days.length +
        config.offset;
      canvas.height = HOURS * config.slotHeight + config.offset;
      const context = canvas.getContext('2d');

      // Units
      for (let i = 0; i < config.units.length * props.days.length; i += 1) {
        context.beginPath();
        context.moveTo(config.slotWidth * i + config.offset, 0);
        context.lineTo(config.slotWidth * i + config.offset, canvas.height);
        context.lineWidth = config.thinLineWidth;
        context.strokeStyle = config.strokeStyle ?? theme.color.border.main;
        context.stroke();
      }

      // Days
      [...Array(WEEKDAYS + 1).keys()].forEach(i => {
        context.beginPath();
        context.moveTo(
          config.slotWidth * config.units.length * i +
            config.offset +
            config.lineWidth,
          0
        );
        context.lineTo(
          config.slotWidth * config.units.length * i +
            config.offset +
            config.lineWidth,
          canvas.height
        );
        context.lineWidth = config.lineWidth;
        context.strokeStyle = config.strokeStyle ?? theme.color.border.main;
        context.stroke();
      });

      // Hours
      [...Array(HOURS).keys()].forEach(i => {
        context.beginPath();
        context.moveTo(
          0,
          config.slotHeight * i + config.offset + config.lineWidth
        );
        context.lineTo(
          canvas.width,
          config.slotHeight * i + config.offset + config.lineWidth
        );
        context.lineWidth = config.lineWidth;
        context.strokeStyle = config.strokeStyle ?? theme.color.border.main;
        context.stroke();
      });

      // Make sure canvas is painted
      // Otherwise we get flashes with wrong timetable dimensions
      setTimeout(() => {
        setLoading(props.loading || false);
      });
    }
  }, [config]);

  function getDateIndex(dt) {
    for (let i = 0; i < props.days.length; i += 1) {
      if (isSameDayDate(new Date(props.days[i]), new Date(dt))) {
        return i;
      }
    }

    // This is just a hack to make sure events
    // that are not in date are hidden. This usually
    // does not happen in production because the
    // backend excludes data.
    return -100;
  }

  function getUnitIndex(unit) {
    for (let i = 0; i < config.units.length; i += 1) {
      if (unit?.id === config.units[i].id) {
        return i;
      }
    }

    // This is just a hack to make sure events
    // that are not in date are hidden. This usually
    // does not happen in production because the
    // backend excludes data.
    return -100;
  }

  function getElementDates(element) {
    let current = new Date(element.start);
    const days = [current];

    while (!isSameDayDate(current, new Date(element.end))) {
      current = addDays(current, 1);
      days.push(current);
    }

    return days.map((d, i) => ({
      index: i,
      start: i === 0 ? new Date(element.start) : setTime(new Date(d), 0, 0),
      allDay: i !== 0 && i !== days.length - 1,
      end:
        i === days.length - 1
          ? new Date(element.end)
          : setTime(new Date(d), 23, 59)
    }));
  }

  /*
  Get the coordiantes of and event/date. There is a mechanism to make sure
  we only place events in config given time steps and do not cross
  days.
  */
  function insertElement(element, date, position) {
    const yPercentage = position.y / elementsRef.current.offsetHeight;
    const yMinutes =
      Math.floor((yPercentage * HOURS * MINUTES) / config.timeStep) *
      config.timeStep;
    const yPixels = (yMinutes / MINUTES) * config.slotHeight + config.offset;

    const xPercentage = position.x / elementsRef.current.offsetWidth;
    const xDay = Math.floor(xPercentage * props.days.length);
    let xUnit =
      Math.floor(xPercentage * props.days.length * config.units.length) %
      config.units.length;

    // If there are more units on this day
    if (xUnit + element.units.length > config.units.length) {
      xUnit = config.units.length - element.units.length;
    }

    const xPixels =
      xDay * config.slotWidth * config.units.length +
      (xUnit / config.units.length) * config.slotWidth * config.units.length +
      config.offset;

    // Make sure outer boundaries applied
    // Left boundary
    let x = xPixels < config.offset ? config.offset : xPixels;
    // Right boundary
    x =
      x >
      elementsRef.current.offsetWidth - element.units.length * config.slotWidth
        ? elementsRef.current.offsetWidth -
          element.units.length * config.slotWidth
        : x;
    // Top boundary
    let y = yPixels < config.offset ? config.offset : yPixels;
    // Bottom boundary
    const height =
      ((new Date(date.end) - new Date(date.start)) / 1000 / MINUTES / MINUTES) *
      config.slotHeight;
    y =
      y > elementsRef.current.offsetHeight - height
        ? elementsRef.current.offsetHeight - height
        : y;

    return {
      x: x,
      y: y
    };
  }

  /*
    Give me some pixels and I tell you which unit is there.
    Optionally give me an event and you get all units for this
    event starting from the selected unit.
    */
  function pixelsToUnits(x, event = undefined) {
    const startIndex = Math.floor(
      ((x + config.offset) / config.slotWidth) % config.units.length
    );

    const endIndex = startIndex + event?.units?.length;

    return event
      ? config.units.slice(startIndex, endIndex)
      : [config.units[startIndex]];
  }

  /*
  Click somewhere on the timetable and you receive the date of
  your destination.
  */
  function pixelsToDate(x, y) {
    const dayIndex =
      Math.ceil((x + config.offset) / config.slotWidth / config.units.length) -
      1;
    const day = props.days[dayIndex];

    const decimalTime = (y - config.offset) / config.slotHeight;
    let hour = Math.ceil(decimalTime);
    let minute =
      Math.ceil(Math.ceil((decimalTime - hour) * MINUTES) / config.timeStep) *
      config.timeStep;

    const hourOutOfBounce =
      hour > 24 || (hour === 24 && Math.abs(minute) === 0);
    if (hourOutOfBounce) {
      hour = 23;
      minute = 55;
    }

    return setTime(day, hour, minute);
  }

  /*
  Handles drag and drop & event click. It recognizes dragging and
  does the correct thing.
  */
  function elementHandler(e, element, date, entity) {
    e.stopPropagation();
    let dragged;
    let position;

    const targets = elementsRef?.current?.querySelectorAll(
      `[data-event='${element.id}']`
    );

    const origins = [];
    let target;
    for (let i = 0; i < targets?.length; i += 1) {
      if (
        Number(targets[i].attributes.getNamedItem('data-date').value) ===
        date.index
      ) {
        target = targets[i];
      }

      origins.push({
        x: targets[i].offsetLeft,
        y: targets[i].offsetTop
      });
    }

    // Make sure that click on label does work by
    // using absolute click position based on date.index
    const rect = elementsRef.current?.getBoundingClientRect();
    const click = {
      left: target?.offsetLeft,
      top: target?.offsetTop,
      x: e.clientX - rect.left - target.offsetLeft,
      y: e.clientY - rect.top - target.offsetTop
    };

    if (
      props.permissions?.dragAndDrop(element, entity) &&
      props.onDragAndDrop &&
      !element.locked
    ) {
      elementsRef.current.style.cursor = 'move';
    }

    const handleMouseMove = e => {
      position = insertElement(element, date, {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top - click.y
      });
      dragged =
        Math.floor(position.x) !== click.left ||
        Math.floor(position.y) !== click.top;

      const relativeX = position.x - click.left;
      const relativeY = position.y - click.top;
      for (let i = 0; i < targets?.length; i += 1) {
        targets[i].style.left = `${origins[i].x + relativeX}px`;
        targets[i].style.top = `${origins[i].y + relativeY}px`;
      }
    };

    const handleMouseUp = e => {
      e.stopPropagation();

      if (
        props.permissions?.dragAndDrop(element, entity) &&
        props.onDragAndDrop &&
        !element.locked
      )
        elementsRef.current.removeEventListener('mousemove', handleMouseMove);

      elementsRef.current.removeEventListener('mouseup', handleMouseUp);

      if (dragged && !element.locked) {
        let start = pixelsToDate(position.x, position.y);
        const timerange = new Date(element.end) - new Date(element.start);
        let end = new Date(start.getTime() + timerange);

        // Check if dragged outside day
        if (!isSameDayDate(start, end)) {
          const maxEnd = setTime(start, 23, 55);
          const rangeOutOfBounce = end - maxEnd;
          start = start - rangeOutOfBounce;
          end = end - rangeOutOfBounce;
        }

        // Get units based on pixels
        const units = pixelsToUnits(position.x, element);

        // Update local state
        const updatedElement = {
          ...element,
          start,
          end,
          units
        };
        updateData(updatedElement, entity);

        // Make sure listeners are removed
        setTimeout(() => {
          props.onDragAndDrop({
            before: element,
            after: updatedElement
          });
        });
      } else {
        if (
          props.permissions?.elementClick(element, entity) &&
          props.onElementClick &&
          target.contains(e.target)
        ) {
          const units = pixelsToUnits(click.x, element);

          // Make sure listeners are removed
          setTimeout(() => {
            props.onElementClick(target, { units, element, entity });
          });
        }
      }

      elementsRef.current.style.cursor = 'default';
    };

    if (
      props.permissions?.dragAndDrop(element, entity) &&
      props.onDragAndDrop &&
      !element.locked
    )
      elementsRef.current.addEventListener('mousemove', handleMouseMove);

    elementsRef.current.addEventListener('mouseup', handleMouseUp);
  }

  /*
  Pass through the clicks inside the timetable to props. It
  only handles clicks for empty spaces. If you click on events the
  `eventHandler` does stuff.
  */
  function handleSlotClick(e) {
    // Make sure only clicks are recognized that are outside of an event
    // by comparing target.id and canvas.id
    if (
      props.permissions?.slotClick(e) &&
      props.onSlotClick &&
      e.target.id === config.id
    ) {
      const units = pixelsToUnits(e.nativeEvent.offsetX);
      const date = pixelsToDate(e.nativeEvent.offsetX, e.nativeEvent.offsetY);

      // Use a click dummy to make sure there is an element we can use
      // on scroll behaviour
      setClickDummy(
        <div
          ref={clickDummyRef}
          style={{
            position: 'absolute',
            left: e.nativeEvent.offsetX,
            top: e.nativeEvent.offsetY,
            height: 0,
            width: 0
          }}
        />
      );

      setTimeout(() => {
        props.onSlotClick(
          clickDummyRef?.current,
          // There is only a single unit possible on mouse click
          { unit: units[0], date }
        );
      }, 100);
    }

    return true;
  }

  /*
  Enables resizing the height/end date of an event/date.
  */
  function resizeDateEnd(e, element, date, entity) {
    e.stopPropagation();

    const rect = elementsRef.current?.getBoundingClientRect();
    const targets = elementsRef?.current?.querySelectorAll(
      `[data-event='${element.id}'][data-date='${date.index}']`
    );
    let end;

    // Add :active selector for styling purposes
    targets[0].focus();

    const handleMouseMove = e => {
      end = pixelsToDate(e.clientX - rect.left, e.clientY - rect.top);

      // Make sure we stay on current day.
      // `pixelsToDate` outputs different day if we move cursor.
      const resizedTime = setTime(date.end, end.getHours(), end.getMinutes());
      const minimumStart = addMinutes(new Date(date.start), config.timeStep);
      end = isBefore(resizedTime, minimumStart) ? minimumStart : resizedTime;

      targets[0].style.height = `${
        date.allDay
          ? 48 * config.slotHeight
          : ((new Date(end) - new Date(date.start)) /
              1000 /
              MINUTES /
              MINUTES) *
            config.slotHeight
      }px`;
    };

    const handleMouseUp = e => {
      elementsRef.current.removeEventListener('mousemove', handleMouseMove);
      elementsRef.current.removeEventListener('mouseup', handleMouseUp);

      // Only update if time changed
      if (element?.end && end && !isSameTime(new Date(element?.end), end)) {
        // Update local state
        const updatedElement = { ...element, end };
        updateData(updatedElement, entity);

        // Make sure listeners are removed
        setTimeout(() => {
          props.onResizeDateEnd({
            before: element,
            after: updatedElement
          });
          targets[0].blur();
        });
      }
    };

    elementsRef.current.addEventListener('mousemove', handleMouseMove);
    elementsRef.current.addEventListener('mouseup', handleMouseUp);
  }

  /*
  Enables resizing the units an event/date.
  */
  function resizeUnits(e, element, date, entity) {
    e.stopPropagation();
    const rect = elementsRef.current?.getBoundingClientRect();
    const targets = elementsRef?.current?.querySelectorAll(
      `[data-event='${element.id}']`
    );

    let units = [...element.units];

    const handleMouseMove = e => {
      const mouseDate = pixelsToDate(
        e.clientX - rect.left,
        e.clientY - rect.top
      );

      // Make sure we only allow resize in same day
      if (isSameDayDate(mouseDate, new Date(date.start))) {
        let mouseUnit = pixelsToUnits(e.clientX - rect.left)[0];

        let included = false;
        for (let i = 0; i < units.length && !included; i++) {
          if (units[i].id === mouseUnit.id) {
            // Remove unit if we mouse over previous unit
            if (i + 1 < units.length) {
              units.splice(i + 1, 1);
            }

            included = true;
          }
        }

        // Make sure we only add units that a right aligned of units
        if (!included && mouseUnit.order > units[units.length - 1].order) {
          units.push(mouseUnit);
        }

        units = units.sort((a, b) => a.order - b.order);

        targets[0].style.width = `${
          units.length * config.slotWidth - config.slotPadding
        }px`;
      }
    };

    const handleMouseUp = e => {
      elementsRef.current.removeEventListener('mousemove', handleMouseMove);
      elementsRef.current.removeEventListener('mouseup', handleMouseUp);

      // Make sure we only update if there is a unit change
      if (
        JSON.stringify(units.map(u => u.id)) !==
        JSON.stringify(element.units.map(u => u.id))
      ) {
        // Update local state
        const updatedElement = { ...element, units };
        updateData(updatedElement, entity);

        // Make sure listeners are removed
        setTimeout(() => {
          props.onResizeUnits({
            before: element,
            after: updatedElement
          });
          targets[0].blur();
        });
      }
    };

    elementsRef.current.addEventListener('mousemove', handleMouseMove);
    elementsRef.current.addEventListener('mouseup', handleMouseUp);
  }

  // ///////////////////////////////////////////////// //
  // OLD Z-INDEX SORT METHOD - NEEDS TO BE REEVALUATED //
  // ///////////////////////////////////////////////// //
  // The z-index of each event is based on the size of the div.
  // The size depens on how many units involved and how long the
  // event is. The following object contains the data sorted by
  // these attributes and make the order accessible by an
  // sorted O(1) object.
  // 1. Take all data
  // const zIndexSorted = [...data.closures, ...data.dates]
  //   .map(d => ({
  //     id: d.id,
  //     // 2. Calculate period/units
  //     period: new Date(d.end) - new Date(d.start),
  //     units: d.units
  //   }))
  //   // 3. Sort based on size
  //   .sort((a, b) => b.period * b.units.length - a.period * a.units.length)
  //   // 4. Prepare sort object by adding index
  //   .map((d, i) => ({ id: d.id, index: i }))
  //   // 5. Reduce array to object to get O(1)
  //   .reduce((result, item) => {
  //     result[item.id] = item;
  //     return result;
  //   }, {});

  const zIndexSorted = [...data.closures, ...data.dates].sort((a, b) =>
    sortByStartDateAsc(a.start, b.start)
  );

  return (
    <div id="timetable-wrapper" ref={wrapperRef} className={classes.wrapper}>
      {!initialized || loading ? (
        <Box width="100%" data-testid="loading-indicator">
          <Box
            display="flex"
            justifyContent="center"
            gap={1}
            spacing={2}
            mt={-0.25}
          >
            {props.customize.domainLabel ? (
              <Skeleton
                variant="text"
                sx={{ fontSize: '1.3rem' }}
                width={156}
              />
            ) : null}
            {props.customize.groupLabel ? (
              <Skeleton variant="text" sx={{ fontSize: '1.3rem' }} width={96} />
            ) : null}
          </Box>
          <Box
            display="flex"
            justifyContent="space-around"
            pl={`${config.timesOffset}px`}
          >
            {props.days.map(day => (
              <Box
                className={classes.dayWrapper}
                px={0}
                key={day.toString()}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '1.3rem' }}
                  width={82}
                />
                {config?.units?.length > 1 ? (
                  <Box
                    ml={2}
                    display="flex"
                    justifyContent="space-around"
                    width={1}
                    mt={0.5}
                    mb={0.9}
                  >
                    {config?.units?.map(u => (
                      <Box
                        key={u.id}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Skeleton variant="circular" width={22} height={22} />
                      </Box>
                    ))}
                  </Box>
                ) : null}
              </Box>
            ))}
          </Box>
          {!initialized ? (
            <Box display="flex" flexDirection="column" gap={5}>
              {[
                ...Array(
                  Math.floor(
                    props.showTill && props.showFrom
                      ? timeStringToHourDecimal(props.showTill) -
                          timeStringToHourDecimal(props.showFrom) +
                          1
                      : 12
                  )
                ).keys()
              ].map(i => (
                <Box key={i} display="flex" alignItems="center">
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: '1rem' }}
                    width={48}
                    mb={2}
                  />
                  <Box className={classes.loadingGrid} width="100%" ml={2} />
                </Box>
              ))}
            </Box>
          ) : null}
        </Box>
      ) : null}
      <div
        style={{
          visibility: !initialized || loading ? 'hidden' : 'visible',
          height: !initialized || loading ? 0 : 'auto'
        }}
        className={classes.labels}
      >
        {props.customize.domainLabel ? (
          <div className={classes.domain}>
            {props.customize.domainLabel(props?.group?.domain)}
          </div>
        ) : null}
        {props.customize.groupLabel ? (
          <div className={classes.group}>
            {props.customize.groupLabel(props.group)}
          </div>
        ) : null}
      </div>
      <div
        style={{
          visibility: !initialized || loading ? 'hidden' : 'visible',
          height: !initialized || loading ? 0 : 'auto'
        }}
        className={classes.days}
      >
        {props.days.map(day => (
          <div key={day.toString()} className={classes.dayWrapper}>
            <div
              className={`${classes.day}${
                isWeekend(day) ? ` ${classes.weekendDay}` : ''
              }`}
            >
              {props.customize?.day?.label
                ? props.customize.day.label(day)
                : TimeTableWeek.defaultProps.customize.day.label(day)}
            </div>
            {config.units?.length > 1 ? (
              <div className={classes.units}>
                {config.units.map((unit, i) => (
                  <Hoverbox
                    mode="click"
                    key={`${i}-unit-label-${unit.id}`}
                    position={props.mode === 'default' ? 'left' : 'bottom'}
                    id={`${i}-unit-label-${unit.id}`}
                    target={
                      <div
                        className={`${
                          classes.unit
                        } ${props.customize?.unit?.className(unit)}`}
                      >
                        {i + 1}
                      </div>
                    }
                  >
                    <div className={classes.unitName}>{unit.name}</div>
                  </Hoverbox>
                ))}
              </div>
            ) : null}
          </div>
        ))}
      </div>
      <div
        style={{
          visibility: !initialized || loading ? 'hidden' : 'visible',
          height: !initialized || loading ? 0 : 'auto'
        }}
      >
        {Object.keys(data).map(entity =>
          data[entity].map(element => {
            if (element.units?.length) return null;

            function getDaysInWeek(days, element) {
              const daysInWeek = [];
              let current = days[0];

              while (isBefore(current, days[days.length - 1])) {
                if (
                  isBetween(
                    setTime(new Date(element.start), 0, 0),
                    current,
                    setTime(new Date(element.end), 0, 0)
                  )
                ) {
                  daysInWeek.push(current);
                }

                current = addDays(current, 1);
              }

              return daysInWeek;
            }

            const daysInWeek = getDaysInWeek(props.days, element);
            const daysBetween = getDatesBetween(
              props.days[0],
              new Date(element.start)
            );

            return (
              <div className={classes.days} key={element.id}>
                {isBetween(
                  setTime(new Date(element.start), 0, 0, 0),
                  props.days[0],
                  setTime(new Date(element.end), 0, 0, 0)
                ) || isSameWeek(props.days[0], new Date(element.start)) ? (
                  <div
                    key={element.name}
                    title={element.name}
                    style={{
                      marginLeft:
                        daysBetween.length *
                        config.slotWidth *
                        config.units.length,
                      width:
                        daysInWeek.length *
                        config.slotWidth *
                        config.units.length
                    }}
                    data-element={element.id}
                    className={`${classes.elementAllDay} ${
                      classes.dayWrapper
                    } ${props.customize?.element?.className(element, entity)}`}
                    data-testclass="time-table-all-day"
                  >
                    {element.name}
                  </div>
                ) : null}
              </div>
            );
          })
        )}
      </div>
      <div
        style={{
          visibility: !initialized || loading ? 'hidden' : 'visible',
          height: !initialized || loading ? 0 : 'auto'
        }}
        className={classes.days}
      >
        {props.days.map(day => (
          <div key={day.toString()} className={classes.dayWrapper}>
            <div className={classes.elementsAllDay}>
              {Object.keys(data).map(entity =>
                data[entity].map(element =>
                  // Check if Element is all-day and current day
                  !element.units?.length &&
                  isSameDayDate(new Date(element.date), day) ? (
                    <div
                      key={element.name}
                      title={element.name}
                      data-element={element.id}
                      className={`${
                        classes.elementAllDay
                      } ${props.customize?.element?.className(
                        element,
                        entity
                      )}`}
                      data-testclass="time-table-all-day"
                    >
                      {element.name}
                    </div>
                  ) : null
                )
              )}
            </div>
          </div>
        ))}
      </div>
      <div className={classes.timetable} data-testid="timetable">
        <div
          className={classes.timeAndEvents}
          style={{
            visibility: !initialized ? 'hidden' : 'visible'
          }}
        >
          <div className={classes.times}>
            {[...Array(HOURS).keys()].map(h =>
              loading ? (
                <Box key={h} className={classes.time}>
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: '1.2rem' }}
                    width={36}
                    mb={2}
                  />
                </Box>
              ) : (
                <div
                  key={`${h}:00`}
                  data-testid={`timetable-time-${h}`}
                  className={classes.time}
                >{`${h}:00`}</div>
              )
            )}
          </div>
          <div
            style={{
              visibility: !initialized ? 'hidden' : 'visible'
            }}
            ref={elementsRef}
            className={classes.elements}
            onClick={e => handleSlotClick(e)}
          >
            <canvas ref={canvasRef} />
            {/* Make sure we only render events on client side because of date time*/}
            {Object.keys(data).map(entity =>
              data[entity]
                .sort((a, b) => sortByStartDateAsc(a.start, b.start))
                .map(element =>
                  // Check if Element is not all-day
                  element.units?.length
                    ? getElementDates(element).map((date, index) => {
                        const viewportOffset =
                          timeStringToHourDecimal(props.showFrom) *
                          config.slotHeight;
                        const top =
                          new Date(date.start).getHours() * config.slotHeight +
                          ((Math.floor(new Date(date.start).getMinutes() / 5) *
                            5) /
                            MINUTES) *
                            config.slotHeight +
                          config.offset;

                        const startDecimal = timeStringToHourDecimal(
                          convertTimeToString(new Date(date.start)).pretty
                        );
                        const endDecimal = timeStringToHourDecimal(
                          convertTimeToString(new Date(date.end)).pretty
                        );
                        const showFromDecimal = timeStringToHourDecimal(
                          props.showFrom
                        );
                        const showTillDecimal = timeStringToHourDecimal(
                          props.showTill
                        );

                        const style = {
                          left:
                            getDateIndex(date.start) *
                              config.slotWidth *
                              config.units.length +
                            (getUnitIndex(
                              element.units.sort((a, b) => a.order - b.order)[0]
                            ) /
                              config.units.length) *
                              config.slotWidth *
                              config.units.length +
                            config.offset,
                          top,
                          height: date.allDay
                            ? 48 * config.slotHeight
                            : ((new Date(date.end) - new Date(date.start)) /
                                1000 /
                                MINUTES /
                                MINUTES) *
                              config.slotHeight,
                          width:
                            element.units.length * config.slotWidth -
                            config.slotPadding,
                          zIndex: zIndexSorted[element.id]?.index,
                          // Calulcate label position
                          // If start and end are not in viewport:
                          // Add padding top to make sure label is visible
                          paddingTop:
                            startDecimal < showFromDecimal &&
                            endDecimal > showTillDecimal
                              ? viewportOffset - top
                              : 0,
                          // Else align the label by justifyContent
                          justifyContent: (() => {
                            // If start is inside of viewport and end is outside
                            // Or both are outside viewport
                            if (
                              (startDecimal > showFromDecimal &&
                                endDecimal > showTillDecimal) ||
                              (startDecimal < showFromDecimal &&
                                endDecimal > showTillDecimal)
                            ) {
                              return 'flex-start';
                            }

                            // If start is outside of viewport and end is inside
                            if (
                              startDecimal < showFromDecimal &&
                              endDecimal < showTillDecimal
                            ) {
                              return 'flex-end';
                            }

                            return 'center';
                          })()
                        };

                        return (
                          <div
                            key={`${element.id}-${convertToString(
                              new Date(date.start),
                              {
                                withTime: false
                              }
                            )}`}
                            // onClick={e => handleEventClick(e, element, date)}
                            onMouseDown={e =>
                              elementHandler(e, element, date, entity)
                            }
                            data-dateseries={element.date_series_id}
                            data-event={element.id}
                            data-date={index}
                            data-testclass="time-table-event"
                            className={`${classes.element} ${
                              props.permissions?.elementClick(
                                element,
                                entity
                              ) && props.onElementClick
                                ? classes.hoverable
                                : ''
                            } ${props.customize?.element?.className(
                              element,
                              entity
                            )}`}
                            style={style}
                            title={
                              props.customize?.element?.title
                                ? props.customize?.element?.title(
                                    element,
                                    entity
                                  )
                                : null
                            }
                          >
                            {props.customize?.element?.label(
                              element,
                              entity,
                              style
                            )}
                            {!element.locked &&
                            props.onResizeUnits &&
                            props.permissions?.resizeUnits(element, entity) ? (
                              <div
                                className={classes.unitsResize}
                                onMouseDown={e =>
                                  resizeUnits(e, element, date, entity)
                                }
                              />
                            ) : null}
                            {!element.locked &&
                            props.onResizeDateEnd &&
                            props.permissions?.resizeDateEnd(
                              element,
                              entity
                            ) ? (
                              <div
                                className={classes.dateEndResize}
                                onMouseDown={e =>
                                  resizeDateEnd(e, element, date, entity)
                                }
                              />
                            ) : null}
                          </div>
                        );
                      })
                    : null
                )
            )}
          </div>
          {clickDummy}
        </div>
      </div>
    </div>
  );
}

TimeTableWeek.propTypes = {
  days: PropTypes.array.isRequired,
  data: PropTypes.shape({
    dates: PropTypes.array,
    closures: PropTypes.array,
    holidays: PropTypes.array,
    public_holidays: PropTypes.array
  }),
  loading: PropTypes.bool,
  group: PropTypes.object.isRequired,
  customize: PropTypes.shape({
    element: PropTypes.shape({
      className: PropTypes.func,
      label: PropTypes.func
    }),
    unit: PropTypes.shape({
      className: PropTypes.func
    }),
    style: PropTypes.shape({
      lineWidth: PropTypes.number,
      strokeStyle: PropTypes.string,
      textColor: PropTypes.string,
      thinLineWidth: PropTypes.number,
      timesOffset: PropTypes.number,
      offset: PropTypes.number
    })
  }),
  permissions: PropTypes.shape({
    slotClick: PropTypes.func,
    elementClick: PropTypes.func,
    dragAndDrop: PropTypes.func,
    resizeDateEnd: PropTypes.func,
    resizeUnits: PropTypes.func
  }),
  onSlotClick: PropTypes.func,
  onElementClick: PropTypes.func,
  onDragAndDrop: PropTypes.func,
  onResizeDateEnd: PropTypes.func,
  showFrom: PropTypes.string,
  showTill: PropTypes.string
};

TimeTableWeek.defaultProps = {
  loading: true,
  days: [],
  data: {
    dates: [],
    closures: [],
    holidays: [],
    public_holidays: []
  },
  group: {
    units: []
  },
  // onSlotClick: (element, { units, date }) => {},
  onSlotClick: undefined,
  // onElementClick: (element, { units, event }) => {},
  onElementClick: undefined,
  // onDragAndDrop: ({ before, after }) => {},
  onDragAndDrop: undefined,
  // onDragAndDrop: ({ before, after }) => {},
  onResizeDateEnd: undefined,
  permissions: {
    slotClick: e => true,
    elementClick: (element, entity) => true,
    dragAndDrop: (element, entity) => true,
    resizeDateEnd: (element, entity) => true,
    resizeUnits: (element, entity) => true
  },
  showFrom: '08:00',
  showTill: '20:00',
  customize: {
    element: {
      className: (element, entity) => undefined,
      label: (element, entity, style) => element.booking_name,
      title: (element, entity, style) => undefined
    },
    unit: {
      className: unit => ''
    },
    day: {
      label: day =>
        convertToString(day, {
          withTime: false,
          withYear: false,
          multiLine: true
        })
    },
    style: null
  }
};
