import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';

import classNames from '../utils/react/classNames';
import Hoverbox from './Hoverbox';
import Icon from './Icon';
import Heading from './Heading';
import Text from './Text';
import Chip from './Chip';

const useStyles = makeStyles(theme => ({
  label: {
    alignItems: 'center',
    color: theme.color.text.main,
    columnGap: theme.spacing(0.5),
    display: 'flex',
    fontFamily: theme.font.primary,
    fontSize: theme.font?.size?.input,
    fontWeight: 'normal',
    lineHeight: 'normal',
    position: props => (props.hidden ? 'absolute' : null),
    width: props => (props.hidden ? 0 : null),
    height: props => (props.hidden ? 0 : null),
    opacity: props => (props.hidden ? 0 : null),
    marginBottom: props => (props.hidden ? 0 : theme.spacing(0.75)),
    '& legend': {
      paddingInline: 0
    },
    ...(theme.components?.label?.root ? theme.components?.label?.root : {})
  },
  asterisks: {
    color: theme.color.error.main,
    paddingLeft: theme.spacing(0.2)
  },
  disabled: {
    color: theme.color.text.light
  },
  infoboxIcon: {
    padding: 0
  },
  indicator: {
    marginLeft: theme.spacing(0.5)
  }
}));

function LabelIndicator({ important, internal, children }) {
  const classes = useStyles();

  return (
    <>
      {children}
      {internal ? (
        <Chip
          className={classes.indicator}
          component="span"
          size="small"
          selected
        >
          {/* TBD: intl */}
          intern
        </Chip>
      ) : null}
      {important ? (
        <Chip
          className={classes.indicator}
          component="span"
          color="warning"
          size="small"
          selected
        >
          {/* TBD: intl */}
          wichtig
        </Chip>
      ) : null}
    </>
  );
}

LabelIndicator.propTypes = {
  children: PropTypes.string,
  important: PropTypes.bool,
  internal: PropTypes.bool
};

function Label(props) {
  const classes = useStyles(props);

  return props.children ? (
    <Box
      className={classNames([
        classes.label,
        props.disabled ? classes.disabled : null,
        props.className
      ])}
    >
      {props.popover?.title && props.popover?.text ? (
        <Hoverbox
          position="bottom"
          id={`infobox-${props.for ?? ''}`}
          mode="click"
          maxWidth="20rem"
          target={(open, handleClick) => (
            <IconButton
              size="small"
              onClick={e => handleClick(e)}
              className={classes.infoboxIcon}
              // TBD: Intl
              aria-label="Sie erhalten eine Erklärung zu dem nachfolgendem Feld"
            >
              <Icon
                name={
                  props.popover?.customIcon
                    ? props.popover?.customIcon
                    : 'info-circle'
                }
              />
            </IconButton>
          )}
        >
          <Heading type="h3">{props.popover?.title}</Heading>
          <Text>{props.popover?.text}</Text>
        </Hoverbox>
      ) : null}
      <props.as
        aria-label={props.ariaLabel}
        htmlFor={props.for}
        id={props.for ? `${props.for}-label` : null}
      >
        {props.children}
        {props.required && (
          // TBD: Intl
          <span
            className={classes.asterisks}
            aria-label="Das nachfolgende Feld ist für das Absenden des Formulars erforderlich"
          >
            {'*'}
          </span>
        )}
      </props.as>
    </Box>
  ) : null;
}

Label.propTypes = {
  ariaLabel: PropTypes.string,
  as: PropTypes.oneOf(['label', 'legend']),
  for: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  hidden: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  popover: PropTypes.shape({
    customIcon: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string
  })
};

Label.defaultProps = {
  ariaLabel: undefined,
  as: 'label',
  disabled: false,
  popover: undefined,
  required: false,
  hidden: undefined,
  className: undefined,
  popover: undefined
};

Label.Indicator = LabelIndicator;
export default Label;
