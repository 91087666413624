import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import Icon from './Icon';
import LoadingIndicator from './LoadingIndicator';

const calculateIconColor = (color, variant) => {
  if (color === 'default') {
    return 'default';
  }

  if (color === 'warning') {
    return 'warning';
  }

  if (color === 'light') {
    return 'light';
  }

  if (variant === 'contained' && color === 'primary') {
    return 'contrastPrimary';
  }

  if (variant === 'contained' && color === 'secondary') {
    return 'contrastSecondary';
  }

  if (color === 'primary') {
    return 'primary';
  }

  if (color === 'secondary') {
    return 'secondary';
  }
};

const useStyles = makeStyles(theme => {
  const calculateBorderRadius = size => {
    switch (size) {
      case 'small':
        return '2px';
      default:
      case 'medium':
        return '5px';
      case 'large':
        return '10px';
    }
  };

  return {
    button: {
      background: props => {
        if (props.variant === 'outlined') {
          return theme.color.common.white;
        }

        if (props.variant === 'text') {
          return 'transparent';
        }

        if (props.color === 'default' || props.color === 'light') {
          return theme.color.common.grey.light;
        }

        if (props.color === 'primary') {
          return props.disabled || props.loading
            ? theme.color.common.grey.light
            : theme.color.primary.main;
        }

        if (props.color === 'secondary') {
          return props.disabled || props.loading
            ? theme.color.secondary.light
            : theme.color.secondary.main;
        }
      },
      width: props => (props.fullWidth ? '100%' : 'auto'),
      fontWeight: 600,
      fontSize: theme.font.size.root,
      borderWidth: '1px',
      borderStyle: 'solid',
      lineHeight: props => {
        switch (props.size) {
          case 'small':
            return '1em';
          default:
          case 'medium':
            return '1.2em';
          case 'large':
            return '1.4em';
        }
      },
      padding: props => {
        if (props.icon) {
          switch (props.size) {
            case 'small':
              return '.375em';
            default:
            case 'medium':
              return '.451em';
            case 'large':
              return '.25em';
          }
        } else if (props.startIcon || props.endIcon || props.avatar) {
          switch (props.size) {
            case 'small':
              return [['0.35em', '0.5em']];
            default:
            case 'medium':
              return [['0.4em', '0.8em']];
            case 'large':
              return [['0.45em', '1em']];
          }
        } else {
          switch (props.size) {
            case 'small':
              return [['0.35em', '1em']];
            default:
            case 'medium':
              return [['0.4em', '2em']];
            case 'large':
              return [['0.45em', '2.5em']];
          }
        }
      },
      borderRadius: props => calculateBorderRadius(props.size),
      borderColor: props => {
        if (props.variant === 'text') {
          return 'transparent';
        }

        if (props.color === 'primary') {
          return props.disabled || props.loading
            ? theme.color.text.light
            : theme.color.primary.main;
        }

        if (props.color === 'secondary') {
          return props.disabled || props.loading
            ? theme.color.secondary.light
            : theme.color.secondary.main;
        }

        if (props.variant === 'outlined') {
          return theme.color.border.main;
        }

        if (props.color === 'default' || props.color === 'light') {
          return theme.color.border.main;
        }
      },
      color: props => {
        if (props.variant === 'text' && props.color === 'default') {
          return props.disabled || props.loading
            ? theme.color.text.light
            : theme.color.text.main;
        }

        if (props.color === 'default' || props.color === 'warning') {
          return props.disabled || props.loading
            ? theme.color.text.light
            : theme.color.text.main;
        }

        if (props.color === 'light') {
          return props.disabled || props.loading
            ? theme.color.common.grey.light
            : theme.color.common.grey.main;
        }

        if (props.variant === 'contained' && props.color === 'primary') {
          return theme.color.primary.contrastText;
        }

        if (props.variant === 'contained' && props.color === 'secondary') {
          return theme.color.secondary.contrastText;
        }

        if (props.color === 'primary') {
          return props.disabled || props.loading
            ? theme.color.text.light
            : theme.color.primary.main;
        }

        if (props.color === 'secondary') {
          return props.disabled || props.loading
            ? theme.color.secondary.light
            : theme.color.secondary.main;
        }
      },
      '& svg': {
        fill: props => {
          if (props.variant === 'text' && props.color === 'default') {
            return props.disabled || props.loading
              ? theme.color.common.grey.light
              : theme.color.common.grey.main;
          }

          if (props.color === 'default') {
            return props.disabled || props.loading
              ? theme.color.common.grey.main
              : theme.color.common.grey.dark;
          }

          if (props.color === 'light') {
            return props.disabled || props.loading
              ? theme.color.common.grey.light
              : theme.color.common.grey.main;
          }

          if (props.variant === 'contained' && props.color === 'primary') {
            return theme.color.primary.contrastText;
          }

          if (props.variant === 'contained' && props.color === 'secondary') {
            return theme.color.secondary.contrastText;
          }

          if (props.color === 'primary') {
            return props.disabled || props.loading
              ? theme.color.text.light
              : theme.color.primary.main;
          }

          if (props.color === 'secondary') {
            return props.disabled || props.loading
              ? theme.color.secondary.light
              : theme.color.secondary.main;
          }
        }
      },
      cursor: props =>
        props.disabled || props.loading ? 'not-allowed' : 'pointer',
      '&:hover': {
        background: props => {
          if (props.disabled || props.loading) {
            return null;
          }

          if (props.variant === 'text' || props.variant === 'outlined') {
            return theme.color.background.grey;
          }

          if (props.color === 'default' || props.color === 'light') {
            return theme.color.background.grey;
          }

          if (props.color === 'primary') {
            return theme.color.primary.dark;
          }

          if (props.color === 'secondary') {
            return theme.color.secondary.dark;
          }
        },
        borderColor: props => {
          if (props.disabled || props.loading) {
            return null;
          }

          if (props.variant === 'text') {
            return 'transparent';
          }

          if (props.color === 'primary') {
            return theme.color.primary.dark;
          }

          if (props.color === 'secondary') {
            return theme.color.secondary.dark;
          }

          if (props.variant === 'outlined') {
            return theme.color.border.dark;
          }

          if (props.color === 'default' || props.color === 'light') {
            return theme.color.common.grey.light;
          }
        }
      },
      '&:hover': {
        outline: 'none',
        textDecoration: 'none'
      },
      '&:focus-visible': {
        outline: `2px solid ${theme.color.primary.dark}`,
        borderColor: theme.color.background.default
      },
      '&:active': {
        background: props => {
          if (props.disabled || props.loading) {
            return null;
          }

          if (props.variant === 'text' || props.variant === 'outlined') {
            return theme.color.background.grey;
          }

          if (props.color === 'default' || props.color === 'light') {
            return theme.color.background.grey;
          }

          if (props.color === 'primary') {
            return theme.color.primary.dark;
          }

          if (props.color === 'secondary') {
            return theme.color.secondary.dark;
          }
        }
      },
      ...(theme.components?.button?.root ? theme.components?.button?.root : {})
    },
    text: {
      fontFamily: theme.font.primary,
      textAlign: props => (props.startIcon || props.avatar ? 'left' : 'center'),
      alignItems: 'center',
      letterSpacing: '1px',
      fontSize: props => {
        switch (props.size) {
          case 'small':
            return '0.75em';
          default:
          case 'medium':
            return theme.font?.size?.input;
          case 'large':
            return '1em';
        }
      },
      ...(theme.components?.button?.text ? theme.components?.button?.text : {})
    },
    endIcon: {
      ...(theme.components?.button?.endIcon
        ? theme.components?.button?.endIcon
        : {})
    },
    loadingIndicator: {
      padding: '0 !important',
      background: 'transparent !important',
      '& span': {
        height: '1rem !important',
        width: '1rem !important',
        color: theme.color.background.default,
        ...(theme.components?.button?.loadingIndicator
          ? theme.components?.button?.loadingIndicator
          : {})
      }
    }
  };
});

const Button = React.forwardRef(function Button(props, ref) {
  const classes = useStyles(props);

  return (
    <Box
      component={props.component}
      aria-label={props['aria-label']}
      aria-describedby={props['aria-describedby']}
      data-testid={props['data-testid']}
      data-selected={props['data-selected']}
      ref={ref}
      form={props.form}
      tabIndex={props.tabIndex != null ? props.tabIndex : 0}
      disabled={props.disabled || props.loading}
      title={props.title}
      alt={props.alt}
      className={`${classes.button} ${props.className}`}
      onClick={props.onClick}
      type={props.type}
      href={props.href}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        {props.avatar && <Box display="inline-flex">{props.avatar}</Box>}
        {props.startIcon && !props.loading && (
          <Box display="inline-flex" mr={0.5}>
            <Icon
              size={props.size}
              name={props.startIcon}
              lib={props.lib}
              disabled={props.disabled}
              color={calculateIconColor(props.color, props.variant)}
            />
          </Box>
        )}

        {props.loading && (
          <Box display="inline-flex" mr={0.5}>
            <LoadingIndicator loading className={classes.loadingIndicator} />
          </Box>
        )}

        {props.icon && !props.loading ? (
          <Icon
            size={props.size}
            name={props.children}
            lib={props.lib}
            disabled={props.disabled}
            color={calculateIconColor(props.color, props.variant)}
          />
        ) : (
          <Box
            display="flex"
            className={classes.text}
            flexGrow={1}
            justifyContent="center"
          >
            {props.children}
          </Box>
        )}

        {props.endIcon && !props.loading && (
          <Box display="inline-flex" ml={0.5} className={classes.endIcon}>
            <Icon
              size={props.size}
              name={props.endIcon}
              lib={props.lib}
              disabled={props.disabled}
              color={calculateIconColor(props.color, props.variant)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
});

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'default',
    'light',
    'warning',
    'commonLight',
    'primary',
    'secondary'
  ]),
  component: PropTypes.any,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  endIcon: PropTypes.string,
  form: PropTypes.string,
  fullWidth: PropTypes.bool,
  icon: PropTypes.bool,
  lib: PropTypes.string,
  title: PropTypes.string,
  alt: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  startIcon: PropTypes.string,
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  avatar: PropTypes.node,
  'data-testid': PropTypes.string,
  'data-selected': PropTypes.bool,
  'aria-label': PropTypes.string
};

Button.defaultProps = {
  'data-selected': null,
  'data-testid': null,
  form: null,
  component: 'button',
  avatar: null,
  children: null,
  className: '',
  color: 'default',
  disabled: false,
  endIcon: null,
  fullWidth: false,
  icon: null,
  lib: 'mui',
  loading: false,
  onClick: undefined,
  size: 'medium',
  startIcon: null,
  title: undefined,
  alt: undefined,
  type: 'button',
  variant: 'text'
};

export default Button;
